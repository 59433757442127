<template>
  <loading v-if="isLoading" />
  <b-row v-else class="cart">
    <b-col cols="12" md="8">
      <b-card class="cart-container">
        <h2 class="mb-2">{{ $t("shopping_cart") }}</h2>
        <div v-if="cartData.items.length > 0">
          <div
            class="shopping-cart-item"
            v-for="(item, index) in cartData.items"
            :key="index"
          >
            <img
              width="150"
              v-if="item.itemable.image && item.itemable.image[0]"
              :alt="item.itemable.name"
              :src="item.itemable.image[0].path"
            />
            <div class="item-content">
              <p class="title">
                {{ $t("name") }} :
                <span class="mx-1 body">{{ item.itemable.name }}</span>
              </p>
              <p class="title">
                {{ $t("price") }} :
                <span class="mx-1 body">
                  {{
                    `${
                      item.itemable.sale
                        ? item.itemable.sale
                        : item.itemable.price
                    } ${currency}`
                  }}
                </span>
                <s v-if="item.itemable.sale">
                  {{ `${item.itemable.price} ${currency}` }}
                </s>
              </p>
              <p class="title">
                {{ $t("subtotal") }} :
                <span class="mx-1 body">
                  {{
                    `${
                      (item.itemable.sale
                        ? item.itemable.sale
                        : item.itemable.price) * +item.qty
                    } ${currency}`
                  }}
                </span>
              </p>
              <div class="item-actions mt-1">
                <div class="qty-controller">
                  <button
                    :disabled="item.qty === 1"
                    @click="decreaseQty(item.id, item.qty)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5.25 12H19.25"
                        :stroke="item.qty === 1 ? '#B8B8B8' : '#2B79AD'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <p>{{ item.qty }}</p>
                  <button
                    :disabled="item.qty === 100"
                    @click="increaseQty(item.id, item.qty)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M13 7.99805H8V12.998C8 13.2633 7.89464 13.5176 7.70711 13.7052C7.51957 13.8927 7.26522 13.998 7 13.998C6.73478 13.998 6.48043 13.8927 6.29289 13.7052C6.10536 13.5176 6 13.2633 6 12.998V7.99805H1C0.734784 7.99805 0.48043 7.89269 0.292893 7.70515C0.105357 7.51762 0 7.26326 0 6.99805C0 6.73283 0.105357 6.47848 0.292893 6.29094C0.48043 6.1034 0.734784 5.99805 1 5.99805H6V0.998047C6 0.73283 6.10536 0.478476 6.29289 0.29094C6.48043 0.103403 6.73478 -0.00195313 7 -0.00195312C7.26522 -0.00195313 7.51957 0.103403 7.70711 0.29094C7.89464 0.478476 8 0.73283 8 0.998047V5.99805H13C13.2652 5.99805 13.5196 6.1034 13.7071 6.29094C13.8946 6.47848 14 6.73283 14 6.99805C14 7.26326 13.8946 7.51762 13.7071 7.70515C13.5196 7.89269 13.2652 7.99805 13 7.99805Z"
                        :fill="item.qty === 100 ? '#B8B8B8' : '#2B79AD'"
                      />
                    </svg>
                  </button>
                </div>
                <b-button variant="outline-danger" @click="deleteItem(item.id)">
                  {{ $t("remove_from_cart") }}
                </b-button>
              </div>
            </div>
            <hr
              v-if="cartData.items.length - 1 !== index"
              style="width: 100%"
            />
          </div>
        </div>
        <div v-else>
          <p style="text-align: center; font-size: 18px">
            {{ $t("there_is_no_items_in_the_cart") }}
          </p>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" md="4">
      <b-card class="coupon-container">
        <h2 class="mb-2">{{ $t("coupons") }}</h2>
        <div class="add-coupon">
          <b-form-input
            :disabled="!!cartData.coupon"
            v-model="coupon"
            :placeholder="
              cartData.coupon
                ? $t('you_already_added_coupon')
                : $t('enter_your_coupon')
            "
          />
          <b-button
            v-if="cartData.coupon"
            variant="danger"
            @click="removeCoupon"
          >
            {{ $t("remove_coupon") }}
          </b-button>
          <b-button v-else class="coupon-action" @click="addCoupon">{{
            $t("apply")
          }}</b-button>
        </div>
      </b-card>
      <b-card class="checkout-container">
        <h2 class="mb-2">{{ $t("cart_totals") }}</h2>
        <div class="chechout-item">
          <p class="title">{{ $t("subtotal") }}</p>
          <p class="body">
            {{ `${cartData.sub_total} ${currency}` }}
          </p>
        </div>
        <hr />
        <div class="chechout-item">
          <p class="title">{{ $t("discount") }}</p>
          <p class="body">
            {{ `${+cartData.sub_total - +cartData.final_total} ${currency}` }}
          </p>
        </div>
        <hr />
        <div class="chechout-item">
          <p class="title">{{ $t("total") }}</p>
          <p class="body">
            {{ `${cartData.final_total} ${currency}` }}
          </p>
        </div>
        <b-button @click="checkout" class="mt-3 checkout-action">
          {{ $t("checkout") }}
        </b-button>
      </b-card>
    </b-col>
    <overlay-component :isLoading="outerOverlay" />
  </b-row>
</template>

<script>
import { BCard, BRow, BCol, BButton, BFormInput } from "bootstrap-vue";
import Loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";

export default {
  components: {
    Loading,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormInput,
    OverlayComponent,
  },
  data() {
    return {
      coupon: null,
      cartData: null,
      isLoading: false,
      outerOverlay: false,
      poundIcon: require("@/assets/images/icons/pound.svg"),
    };
  },
  created() {
    this.getCartData(false);
    if (this.$route.query.checkout) {
      this.$helpers.makeToast(
        "danger",
        this.$t("failed"),
        this.$t("checkout_has_been_failed")
      );
    }
  },
  computed: {
    currency() {
      console.log(this.$store.state?.userData?.userData?.country?.currency);
      return this.$store.state?.userData?.userData?.country?.currency;
    },
  },
  methods: {
    async getCartData(overlay = false) {
      try {
        overlay ? (this.outerOverlay = true) : (this.isLoading = true);
        const res = await this.$http.get("/guardian/cart");
        this.cartData = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        overlay ? (this.outerOverlay = false) : (this.isLoading = false);
      }
    },
    async increaseQty(itemId, qty) {
      try {
        this.outerOverlay = true;

        const newQty = +qty + 1;
        const formData = new FormData();
        formData.append("qty", newQty);
        await this.$http.post(`/guardian/cart/${itemId}?_method=put`, formData);
        this.getCartData(true);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        setTimeout(() => {
          this.outerOverlay = false;
        }, 1000);
      }
    },
    async decreaseQty(itemId, qty) {
      try {
        this.outerOverlay = true;
        const newQty = +qty - 1;
        const formData = new FormData();
        formData.append("qty", newQty);
        await this.$http.post(`/guardian/cart/${itemId}?_method=put`, formData);
        this.getCartData(true);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        setTimeout(() => {
          this.outerOverlay = false;
        }, 1000);
      }
    },
    async deleteItem(itemId) {
      try {
        this.outerOverlay = true;
        await this.$http.delete(`/guardian/cart/${itemId}`);
        await this.getCartData(true);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
    async addCoupon() {
      try {
        this.outerOverlay = true;

        const formData = new FormData();
        formData.append("coupon_code", this.coupon);
        await this.$http.post("/guardian/apply-coupon", formData);
        await this.getCartData(true);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
    async removeCoupon() {
      try {
        this.outerOverlay = true;
        await this.$http.post("/guardian/detach-coupon");
        await this.getCartData(true);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
    async checkout() {
      try {
        this.outerOverlay = true;
        const formData = new FormData();
        formData.append(
          "success_url",
          "https://futureskill-p.geexar.dev/#/checkout"
        );
        formData.append(
          "cancel_url",
          "https://futureskill-p.geexar.dev/#/cart?checkout=failed"
        );
        const res = await this.$http.post("/guardian/checkout", formData);
        if (res.data.url) {
          window.location.href = res.data.url;
        } else {
          this.$helpers.makeToast(
            "danger",
            this.$t("failed"),
            this.$t("checkout_failed")
          );
        }
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("checkout_completed_successfully")
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.outerOverlay = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
